import React from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {AppLayout} from "./pages/AppLayout";
import {PrivacyPolicy} from "./pages/privacypolicy/PrivacyPolicy";
import {TermsAndConditions} from "./pages/termsandconditions/TermsAndConditions";


const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout/>,
      children:[
        {
          path: "privacypolicy/:appName",
          element: <PrivacyPolicy />,
        },
        {
          path: "termsandconditions/:appName",
          element: <TermsAndConditions />,
        },
      ]
  },
]);

function App() {
  return (
        <RouterProvider router={router}/>
  );
}

export default App;
